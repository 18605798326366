import React, { useState } from 'react'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

// Check password format
function isPasswordOk(pwd) {
    const pwdReqs = "Password must be at least 8 character, have 1 capital, 1 number, and 1 special character";
    if (pwd.length < 8) {
        alert(pwdReqs);
        return false;
    }
    // check capital, num
    if (!pwd.match(/[A-Z]/) || !/\d/.test(pwd)) {
        alert(pwdReqs)
        return false;
    }

    return true;
}


export default function Signup(props) {
    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth(props.app);
    let [userEmail, setUserEmail] = useState("");
    const [userPass, setUserPass] = useState("");
    const [userPass2, setUserPass2] = useState("");

    let [buttonsDisabled, setButtonsDisabled] = useState(false);

    const navigate = useNavigate();
    const emailRegex = /[a-zA-Z0-9]+@[a-zA-Z]+\.[a-z]{2,3}/;


    // Handle Signup
    const handleSignup = () => {
        setButtonsDisabled(true);
        userEmail = userEmail.trim();

        // Check user Email format and domain
        if (!emailRegex.test(userEmail)) {
            setButtonsDisabled(false);
            alert("Email Invalid");
            return
        }

        // Check Password
        if (userPass !== userPass2) {
            alert("Passwords do not match");
            setButtonsDisabled(false);
            return
        } else if (!isPasswordOk(userPass) || !isPasswordOk(userPass2)) {
            alert("Password is invalid. It should be 8 Characters, have a capital letter and number.");
            setButtonsDisabled(false);
            return;
        }

        // email/password is valid so create the user
        createUserWithEmailAndPassword(auth, userEmail, userPass)
            .then((userCredential) => {

                // Send email verification
                console.log("SENDING FROM handleSignUp");
                sendEmailVerification(auth.currentUser)
                    .then(() => {
                        alert("Please check your email for a verification link and return to sign-in.");
                        navigate("/")
                    })

            })
            .catch((error) => {
                const errorMessage = error.message;
                alert("Unable to create user: " + errorMessage);
                // console.log("errorCode: ", errorCode);
                // console.log("errorMessage: ", errorMessage);
            })
        setButtonsDisabled(false);
    }


    return (
        <div>
            <div id='loginBox' className='max-w-25 center m-top-5 whiteBg'>
                <div className='m-top-3 text-center f justify-end'>
                    <label className='f-grow-1' for='email'>Email:</label>
                    <input onChange={(e) => setUserEmail(e.target.value)} name='email' className='' type={"email"} />
                </div>
                <p className='m-top-3' style={{ fontSize: "small" }}>Password must be 8 characters, have 1 capital letter, and 1 number</p>
                <div className='text-center f justify-end'>
                    <label className='f-grow-1' for='pwd'>Password:</label>
                    <input onChange={(e) => setUserPass(e.target.value)} name='pwd' className='' type={"password"} />
                </div>
                <div className='m-top-3 text-center f justify-end'>
                    <label className='f-grow-1' for='pwd'>Re-type Password:</label>
                    <input onChange={(e) => setUserPass2(e.target.value)} name='pwd' className='' type={"password"} />
                </div>
                <div className='text-center'>
                    {/* <button disabled={buttonsDisabled} onClick={() => handleSignIn()} className='m-top-3'>Login</button><br/> */}
                    <button disabled={buttonsDisabled} onClick={() => handleSignup()} className='m-top-3'>Sign Up</button>
                </div>
            </div>
        </div>
    )
}
