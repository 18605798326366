import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise"
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';


// Currency Formater
const currencyFormatter = (currency, sign) => {
    if (currency === '' || currency === null || currency === undefined) {
        return `${sign}0.00`;
    }

    var sansDec = `${currency.toFixed(2)}`;
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (currency < 0) {
        return `(${sign}${formatted.replace("-", "")})`
    }
    return sign + `${formatted}`;
}

export function LeadData(props) {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [filterModel, setFilterModel] = useState(null);  // Save current filters


    // Grid Options
    const gridOptions = {
        rowGroupPanelShow: 'always',
        sideBar: true,
        grandTotalRow: true,
        getRowStyle: params => {
            // Grand Total row formatting
            if (params.node.group === true && params.node.level === -1) {
                return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
                // #b5c4ca
                // #99bac9
            }
            // Subtotal row formatting
            else if (params.node.group === true) {
                return { background: '#e8e8e8', fontWeight: 'bold' };
            }
        },
        autoGroupColumnDef: {
            cellRendererParams: {
              footerValueGetter: params => {
                const isRootLevel = params.node.level === -1;
                if (isRootLevel) {
                  return 'Grand Total';
                }
                return `Sub Total (${params.value})`;
              },
            },
            headerName: 'Source',
            pinned: 'left',
          },
    };

    // Group Row Agg func
    const getGroupRowAgg = useCallback((params) => {
        let result = {
            tax_liability: 0,
            amount_owed: 0,
            tagged_initial: 0,
            gross_sale: 0,
            total_payments: 0,
        };

        params.nodes.forEach((node) => {
            const data = node.group ? node.aggData : node.data
            // Increment Form Selected Debt
            result.tax_liability += data.tax_liability ? data.tax_liability : 0;
            // Increment Amount Owed
            result.amount_owed += data.amount_owed ? data.amount_owed : 0;
            // Increment Initial Payment
            result.tagged_initial += data.tagged_initial ? data.tagged_initial : 0;
            // Increment Gross Sale
            result.gross_sale += data.gross_sale ? data.gross_sale : 0;
            // Increment Total Payments
            result.total_payments += data.total_payments ? data.total_payments : 0;
        });

        return result;
    });


    // Get data from sources
    useEffect(() => {
        props.setLoading(true); // Set loading to true before fetching
        if (!props.userSources || !props.startDate || !props.endDate) {
            console.log("No sources or dates");
            return
        }

        if (gridRef.current) {
            // Save current filter state
            const currentFilterModel = gridRef.current.api.getFilterModel();
            setFilterModel(currentFilterModel);
        }

        // setRowData([]); // This might be an issue
        let sourceCodeArray = props.userSources.sources.split(",");
        let sourceCodeString = "";
        sourceCodeArray.forEach((element, index) => {
            sourceCodeString += "'" + element + "'"
            if (index < sourceCodeArray.length - 1) {
                sourceCodeString += ", "
            }
        });

        // Set Request Options
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + process.env.REACT_APP_DB_AUTH,
                'Accept': '*',
            },
            body: JSON.stringify({
                "startDate": props.startDate,
                "endDate": props.endDate,
                "userSources": sourceCodeString,
            })
        };

        // Request vendor data
        const reqUrl = process.env.REACT_APP_API_URL + "vendor/lead_data/" + props.dateField;

        fetch(reqUrl, requestOptions)
            .then(response => response.json())
            .then(resp => {
                setRowData(resp.data);
                props.setLoading(false); // Set loading to false after data is fetched
            })
    }, [props.userSources, props.startDate, props.endDate, props.dateField]);

    // After new data is set, reapply the filter model
    useEffect(() => {
        if (gridRef.current && filterModel) {
            gridRef.current.api.setFilterModel(filterModel);
        }
    }, [rowData]);

    // Default Column Def
    const defaultColDef = {
        filter: true,
        enableRowGroup: true
    };

    // Instantiate Col Defs
    let colDefs = [];
    if (props.userSources && props.userSources.admin === "0") {
        // Vendor Column Defnitions
        colDefs = [
            { field: "description", headerName: "Source", rowGroup: true, hide: true, sort: "asc",
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.marketing_line}` : '';
                } 
            },
            { field: "case_number", headerName: "Case Number" },
            { field: "name", headerName: "Name" },
            { field: "email", headerName: "Email" },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "sub_id", headerName: "Sub ID" }
        ];
    } else if (props.userSources && props.userSources.admin === "1") {
        // Admin Column Definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true, sort: "asc",
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.marketing_line}` : '';
                } 
            },
            { field: "case_number", headerName: "Case Number" },
            { field: "name", headerName: "Name" },
            { field: "cell", headerName: "Cell", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "home", headerName: "Home", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "work_phone", headerName: "Work", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "sub_id", headerName: "Sub ID" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "gross_sale", headerName: "Total Invoiced", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "total_payments", headerName: "Total Paid", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
        ];
    } else if (props.userSources && props.userSources.admin === "2") {
        // No name column definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true, 
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.marketing_line}` : '';
                } 
            },
            { field: "case_number", headerName: "Case Number" },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "sub_id", headerName: "Sub ID" }
        ];
    } else if (props.userSources && props.userSources.admin === "3") {
        // No name column definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true, 
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.marketing_line}` : '';
                } 
            },
            { field: "case_number", headerName: "Case Number" },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") }
        ];
    } else if (props.userSources && props.userSources.admin === "4") {
        // No name column definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true, 
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.marketing_line}` : '';
                } 
            },
            { field: "case_number", headerName: "Case Number" },
            { field: "cell", headerName: "Cell", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "home", headerName: "Home", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "work_phone", headerName: "Work", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") }
        ];
    } else if (props.userSources && props.userSources.admin === "5") {
        // Vendor Column Defnitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true, 
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.marketing_line}` : '';
                } 
            },
            { field: "case_number", headerName: "Case Number" },
            { field: "name", headerName: "Name" },
            { field: "email", headerName: "Email" },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "sub_id", headerName: "Sub ID" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") }
        ];
    } else if (props.userSources && props.userSources.admin === "6") {
        // No name column definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true, 
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.marketing_line}` : '';
                } 
            },
            { field: "case_number", headerName: "Case Number" },
            { field: "state", headerName: "State" },
            { field: "cell", headerName: "Cell", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "home", headerName: "Home", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "work_phone", headerName: "Work", valueFormatter: (params) => { return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") }
        ];
    }

    // Autosize the grouped column when the grid is first rendered
    const onFirstDataRendered = (params) => {
        setTimeout(() => {
            params.columnApi.autoSizeAllColumns();
        },100);
    };

    // Autosizes all columns when row group is expanded
    const onRowGroupOpened = (params) => {
        setTimeout(() => {
            params.columnApi.autoSizeAllColumns();
        },100);
    };


    return (
        <div>
            <div className='ag-theme-quartz h-75 m-top-3 w-95 center' style={{ position: 'relative' }}>
                <AgGridReact
                    onFirstDataRendered={onFirstDataRendered}
                    onRowGroupOpened={onRowGroupOpened}
                    enableCharts={true}
                    enableRangeSelection={true}
                    gridRef={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    getGroupRowAgg={getGroupRowAgg}
                    gridOptions={gridOptions}
                    defaultColDef={defaultColDef}
                    modules={[ServerSideRowModelModule, RowGroupingModule]}
                    defaultSideBar={false}
                    sideBar={{
                        toolPanels: [
                            {
                                id: 'columns',
                                labelDefault: 'Columns',
                                labelKey: 'columns',
                                iconKey: 'columns',
                                toolPanel: 'agColumnsToolPanel',
                            },
                            {
                                id: 'filters',
                                labelDefault: 'Filters',
                                labelKey: 'filters',
                                iconKey: 'filter',
                                toolPanel: 'agFiltersToolPanel',
                            }
                        ]
                    }}
                />
                {props.loading && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
            </div>
        </div>
    )
};

export default LeadData;
