import React, { useState } from 'react';
import './ToggleButton.css';

export function ToggleButton(props) {
  
    return props.dataTypes ? (
      <div style={{ display: 'flex' }}>
        {props.dataTypes.split(",").map((value) => (
          <button
          className={`toggleButton ${props.dataTypeToggleValue === value.trim() ? 'active' : ''} large-font`}
          key={value.trim()}
          onClick={() => props.onToggleChange(value.trim())}
      >
          {value.charAt(0).toUpperCase() + value.slice(1)}
      </button>
      
        ))}
      </div>
    ) : null;
};

export default ToggleButton;