
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import trhLogo from '../static/trhLogo.png';
import LeadData from '../leadData/LeadData';
import CallData from '../callData/CallData';
import ToggleButton from '../toggleButton/ToggleButton';
import './Dashboard.css';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import 'react-toggle/style.css';
import { set } from 'date-fns';


// Get sources for the user's email
const getUserSources = (loggedInUser, vendorMap) => {
  if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
      return null;
  }
  let sources = vendorMap[loggedInUser.email];
  return sources;
}

// 1 week ago's date
const weekAgoDate = () => {
    const today = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    const laToday = new Date(today);
    const dt = new Date(laToday);
    dt.setDate(laToday.getDate() - laToday.getDay() - 5);
    return dt;
};

// Yesterdays date
const yesterdaysDate = () => {
    const today = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    const laToday = new Date(today);
    const dt = new Date(laToday);
    dt.setDate(laToday.getDate() - laToday.getDay() + 1); // 
    return dt;
};

// Current Week Start Date (Monday)
const currentWeekStart = () => {
    const today = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    const laToday = new Date(today);
    const dt = new Date(laToday);
    dt.setDate(laToday.getDate() - laToday.getDay() + 1); // Set to Monday of current week
    return dt;
}

// Current Week Start Date (Saturday)
const currentWeekEnd = () => {
    const today = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    const laToday = new Date(today);
    const dt = new Date(laToday);
    dt.setDate(laToday.getDate() - laToday.getDay() + 6); // Set to Saturday of current week
    return dt;
}

// Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase Authentication and get a reference to the service
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export function Dashboard(props) {
  const [userSources, setUserSources] = useState(null);
  const [dateField, setDateField] = useState("date");
  const datePickerRef = useRef(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [dataTypeToggleValue, setDataTypeToggleValue] = useState("Leads");
  const [reportComp, setReportComp] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state to track when data is being fetched
  let [loggedIn, setLoggedIn] = useState(true);
  
// Need this also to display the date range in the date picker
  // It's a list b/c you can do several date ranges (not applicable to our case)
  const [calendarDates, setCalendarDates] = useState(() => {
    return [{
        startDate: weekAgoDate(),
        endDate: yesterdaysDate(),
        key: 'selection'
    }];
  });

  const navigate = useNavigate();


    // Get user sources
  useEffect(() => {
    if (loggedInUser) {
        setUserSources(getUserSources(loggedInUser, props.vendorMap));

        // Set the default dataTypeToggleValue based on the first data type source
        if (userSources && userSources.dataTypes.split(",").length > 0) {
            setDataTypeToggleValue(userSources.dataTypes.split(",")[0].trim());
        }
    }
  }, [loggedInUser, props.vendorMap]);
  
  // If user clicks the Calendar toggle and wants to close it by clicking outside of it,
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Check logged in state
  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user && user.emailVerified) {
              setLoggedInUser(user);
          } else {
              console.log("NOT LOGGED IN/VERIFIED");
              navigate("/");
          }
      });

      // Clean up the subscription on unmount
      return () => unsubscribe();
  }, [navigate]);


  // Map containing our components
  const COMP_MAP = {
    'LeadData': <LeadData 
      startDate={calendarDates[0].startDate.toLocaleDateString()} 
      endDate={calendarDates[0].endDate.toLocaleDateString()} 
      dateField={dateField}
      userSources={userSources}
      app={app}
      loading={loading}
      setLoading={setLoading}
      />,
    'CallData': <CallData 
      startDate={calendarDates[0].startDate.toLocaleDateString()} 
      endDate={calendarDates[0].endDate.toLocaleDateString()} 
      dateField={dateField}
      userSources={userSources}
      app={app}
      loading={loading}
      setLoading={setLoading}
      />
  }

  // Helper function to enable the Date Type toggle on the applicable component (FormData)
  const enableDateTypeToggle = () => {
    const dateTypeToggle = document.querySelectorAll('[name="selectDateField"]');
    if (dateTypeToggle) {
      dateTypeToggle.forEach(toggle => {
        toggle.disabled = false;
      });
    }
  }

  // Helper function to disable the Date Type toggle on the applicable component (CallData)
  const disableDateTypeToggle = () => {
    const dateTypeToggle = document.querySelectorAll('[name="selectDateField"]');
    if (dateTypeToggle) {
      dateTypeToggle.forEach(toggle => {
        toggle.disabled = true;
      });
    }
  }

  useEffect(() => {
    if (userSources) {
      if (dataTypeToggleValue === 'Leads') {
        setReportComp(COMP_MAP['LeadData']);

        // Also enable the Create Date and Sale Date toggle
        enableDateTypeToggle();
      }
      else if (dataTypeToggleValue === 'Calls') {
        setReportComp(COMP_MAP['CallData']);

        // Also disable the Create Date and Sale Date toggle
        disableDateTypeToggle();
      }
    }
  }, [userSources, calendarDates, dateField, dataTypeToggleValue, loading]);

  
  if(!loggedIn)  {
    return navigate("/");
  }

  const gotoDashboard = () => {
    return navigate("/dashboard");
  }

    // Add this function to format the date range display
  const formatDateRange = () => {
    return `${calendarDates[0].startDate.toLocaleDateString()} - ${calendarDates[0].endDate.toLocaleDateString()}`;
  };

  // Update the field that will filter the cases
  const updateDateField = (dateField) => {
    setDateField(dateField);
    // render()
  };

  // Log User Out
  const userLogOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/")
      })
      .catch((error) => {
        alert("Unable to sign out: " + error.message);
      })
  }


  

  
  return (
    <div className=''>
      <div className='topBar'>
        <div className='f justify-center align-center'>
          <div className='f center-text'>
            <img src={trhLogo} alt="Logo" height={50} className='m-right-2' />
          </div>
          <div className='f center-text m-left-2'>
              <button className='blueButton' onClick={(e) => userLogOut(e)}>Log Out</button>
            </div>
          <div className='f gap-5 m-left-2'>
             {/* Change Date field */}
              <label>Create Date: </label>
              <input type='radio' name='selectDateField' value={'date'} onChange={(e) => updateDateField(e.target.value)} checked={dateField === 'date'} /> <br />
              <label>Sale Date: </label>
              <input type='radio' name='selectDateField' value={'sale_date'} onChange={(e) => updateDateField(e.target.value)} checked={dateField === 'sale_date'}/> <br />
          </div>
          <div className='date-picker-container m-left-2' ref={datePickerRef}>
          <label className='large-font'>Date Range: </label>
            <button 
              className='large-font rounded-5 dropdown-input' 
              onClick={() => setShowDatePicker(!showDatePicker)}>
                {formatDateRange()}
            </button>
            
            {showDatePicker && (
              <div>
                <DateRangePicker
                    className='date-picker-wrapper'
                    onChange={item => {
                      setCalendarDates([item.selection]);
                    }}
                    showSelectionPreview={true}
                    // moveRangeOnFirstSelection={false}
                    // retainEndDateOnFirstSelection={true}
                    months={1}
                    ranges={calendarDates}
                    direction="vertical"
                    editableDateInputs={true}
                    dateDisplayFormat="MM/dd/yyyy"
                />
                </div>
            )}
          </div>
          { userSources && 
            <div className='f center-text m-left-2'>
                <ToggleButton 
                dataTypeToggleValue={dataTypeToggleValue}
                onToggleChange={setDataTypeToggleValue}
                dataTypes={userSources.dataTypes}
                />
            </div>
            }
        </div>
      </div>
      <div id="reportBox">{reportComp}</div>
    </div>
  );
};
export default Dashboard;
