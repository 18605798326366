// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleButton {
    padding: 8px 16px;
    margin: 0;
    border: 1px solid #3F91B8;
    background: white;
    cursor: pointer;
    border-radius: 0;
    box-shadow: 5px 3px 5px grey;
}

.toggleButton:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.toggleButton:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.toggleButton.active {
    background: #3F91B8;
    color: white;
}

.toggleButton:hover {
    background: #1976d2;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/toggleButton/ToggleButton.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,SAAS;IACT,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;AAClC;;AAEA;IACI,4BAA4B;IAC5B,+BAA+B;AACnC;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".toggleButton {\n    padding: 8px 16px;\n    margin: 0;\n    border: 1px solid #3F91B8;\n    background: white;\n    cursor: pointer;\n    border-radius: 0;\n    box-shadow: 5px 3px 5px grey;\n}\n\n.toggleButton:first-child {\n    border-top-left-radius: 4px;\n    border-bottom-left-radius: 4px;\n}\n\n.toggleButton:last-child {\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px;\n}\n\n.toggleButton.active {\n    background: #3F91B8;\n    color: white;\n}\n\n.toggleButton:hover {\n    background: #1976d2;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
