import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise"
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

// Get sources for the user's email
const getUserSources = (loggedInUser, vendorMap) => {
    if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
        return null;
    }
    let sources = vendorMap[loggedInUser.email];
    // console.log("sources: ", sources);
    return sources;
}

// Yesterdays date
const yesterdaysDate = () => {
    const today = new Date();
    const losAngelesTime = new Date(today.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    let yesterday = new Date(losAngelesTime);
    yesterday.setDate(losAngelesTime.getDate() - 1);
    yesterday.setHours(today.getHours() - losAngelesTime.getHours());
    const formattedYesterday = yesterday.toISOString().split('T')[0];
    return formattedYesterday;
};

// 1 week ago's date
const weekAgoDate = () => {
    const today = new Date();
    const losAngelesTime = new Date(today.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
    let yesterday = new Date(losAngelesTime);
    yesterday.setDate(losAngelesTime.getDate() - 7);
    yesterday.setHours(today.getHours() - losAngelesTime.getHours());
    const formattedYesterday = yesterday.toISOString().split('T')[0];
    return formattedYesterday;
};

// Currency Formater
const currencyFormatter = (currency, sign) => {
    if (currency === '' || currency === null || currency === undefined) {
        return `${sign}0.00`;
    }
    // console.log("currency: ", currency);

    var sansDec = `${currency.toFixed(2)}`;
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (currency < 0) {
        return `(${sign}${formatted.replace("-", "")})`
    }
    return sign + `${formatted}`;
}

export default function Dashboard(props) {
    const gridRef = useRef();
    const auth = getAuth(props.app);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [userSources, setUserSources] = useState(null);
    const [startDate, setStartDate] = useState(weekAgoDate);
    const [endDate, setEndDate] = useState(yesterdaysDate);
    const [rowData, setRowData] = useState([]);
    const [dateField, setDateField] = useState("date");
    const navigate = useNavigate();

    

    // Grid Options
    const gridOptions = {
        rowGroupPanelShow: 'always',
        sideBar: true,
        grandTotalRow: true,
        getRowStyle: params => {
            // Grand Total row formatting
            if (params.node.group === true && params.node.level === -1) {
                return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
                // #b5c4ca
                // #99bac9
            }
            // Subtotal row formatting
            else if (params.node.group === true) {
                return { background: '#e8e8e8', fontWeight: 'bold' };
            }
        },
    };

    // Group Row Agg func
    const getGroupRowAgg = useCallback((params) => {
        let result = {
            tax_liability: 0,
            amount_owed: 0,
            tagged_initial: 0,
            gross_sale: 0,
            total_payments: 0,
        };

        params.nodes.forEach((node) => {
            const data = node.group ? node.aggData : node.data
            // Increment Form Selected Debt
            // console.log("data.tax_liability: ", data.tax_liability);
            result.tax_liability += data.tax_liability ? data.tax_liability : 0;
            // Increment Amount Owed
            result.amount_owed += data.amount_owed ? data.amount_owed : 0;
            // Increment Initial Payment
            result.tagged_initial += data.tagged_initial ? data.tagged_initial : 0;
            // Increment Gross Sale
            result.gross_sale += data.gross_sale ? data.gross_sale : 0;
            // Increment Total Payments
            result.total_payments += data.total_payments ? data.total_payments : 0;
        });

        return result;
    });

    // Log User Out
    const userLogOut = () => {
        signOut(auth)
            .then(() => {
                navigate("/")
            })
            .catch((error) => {
                alert("Unable to sign out: " + error.message);
            })
    }

    // Check logged in state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && user.emailVerified) {
                setLoggedInUser(user);
            } else {
                console.log("NOT LOGGED IN/VERIFIED");
                navigate("/");
            }
        });

        // Clean up the subscription on unmount
        return () => unsubscribe();
    }, [auth, navigate]);

    // Get user sources
    useEffect(() => {
        if (loggedInUser) {
            setUserSources(getUserSources(loggedInUser, props.vendorMap));
        }
    }, [loggedInUser, props.vendorMap]);

    // Get data from sources
    useEffect(() => {
        if (!userSources || !startDate || !endDate) {
            return
        }
        setRowData([]);
        let sourceCodeArray = userSources.sources.split(",")
        let sourceCodeString = "";
        sourceCodeArray.forEach((element, index) => {
            sourceCodeString += "'" + element + "'"
            if (index < sourceCodeArray.length - 1) {
                sourceCodeString += ", "
            }
        });

        // Set Request Options
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + process.env.REACT_APP_DB_AUTH,
                'Accept': '*',
            },
            body: JSON.stringify({
                "startDate": startDate,
                "endDate": endDate,
                "userSources": sourceCodeString,
            })
        };

        // Request vendor data
        const reqUrl = process.env.REACT_APP_API_URL + "vendor/report/" + dateField;

        // console.log("reqUrl: ", reqUrl);
        fetch(reqUrl, requestOptions)
            .then(response => response.json())
            .then(resp => {
                // console.log("resp.data: ", resp.data);
                setRowData(resp.data);
            })
    }, [userSources, startDate, endDate, dateField]);

    // Default Column Def
    const defaultColDef = {
        filter: true,
        enableRowGroup: true
    };

    // Update the field that will filter the cases
    const updateDateField = (dateField) => {
        setDateField(dateField);
        // render()
    };

    // Instantiate Col Defs
    let colDefs = [];
    if (userSources && userSources.admin === "0") {
        // Vendor Column Defnitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true },
            { field: "case_number", headerName: "Case Number" },
            { field: "name", headerName: "Name" },
            { field: "email", headerName: "Email" },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "sub_id", headerName: "Sub ID" }
        ];
    } else if (userSources && userSources.admin === "1") {
        // Admin Column Definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true },
            { field: "case_number", headerName: "Case Number" },
            { field: "name", headerName: "Name" },
            { field: "cell", headerName: "Cell", valueFormatter: (params) => {return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "home", headerName: "Home", valueFormatter: (params) => {return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } }, 
            { field: "work_phone", headerName: "Work", valueFormatter: (params) => {return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } }, 
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "sub_id", headerName: "Sub ID" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "gross_sale", headerName: "Total Invoiced", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "total_payments", headerName: "Total Paid", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
        ];
    } else if (userSources && userSources.admin === "2") {
        // No name column definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true },
            { field: "case_number", headerName: "Case Number" },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "sub_id", headerName: "Sub ID" }
        ];
    } else if (userSources && userSources.admin === "3") {
        // No name column definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true },
            { field: "case_number", headerName: "Case Number" },
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") }
        ];
    } else if (userSources && userSources.admin === "4") {
        // No name column definitions
        colDefs = [
            { field: "marketing_line", headerName: "Source", rowGroup: true, hide: true },
            { field: "case_number", headerName: "Case Number" },
            { field: "cell", headerName: "Cell", valueFormatter: (params) => {return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } },
            { field: "home", headerName: "Home", valueFormatter: (params) => {return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } }, 
            { field: "work_phone", headerName: "Work", valueFormatter: (params) => {return params.value ? `(${params.value.slice(0, 3)}) ${params.value.slice(3, 6)}-${params.value.slice(6)}` : null } }, 
            { field: "date", headerName: "Create Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null }, sort: 'asc' },
            { field: "sale_date", headerName: "Sale Date", valueFormatter: (params) => { return params.value ? params.value.split('T')[0] : null } },
            { field: "tax_liability", headerName: "Form Selected Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "amount_owed", headerName: "Confirmed Debt", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") },
            { field: "status", headerName: "Status" },
            { field: "tagged_initial", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, valueFormatter: params => currencyFormatter(params.value, "$") }
        ];
    }


    return (
        <div>
            <div className='f pad-1'>
                <button onClick={() => userLogOut()}>Log Out</button>
            </div>
            <div className='f justify-center m-top-3 blueBg pad-3 w-50 center white-border-1 rounded'>
                {/* Start Date */}
                <div className=''>
                    <label className='white-text'>Start Date: </label>
                    <input
                        name='startDate'
                        className='large-font m-left-2'
                        type='date'
                        defaultValue={weekAgoDate()}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                {/* End Date */}
                <div className='m-left-2'>
                    <label className='white-text'>End Date: </label>
                    <input
                        name='endDate'
                        className='large-font m-left-2'
                        type='date'
                        defaultValue={yesterdaysDate()}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
                {/* Change Date field */}
                <div className='white-text m-left-2 right-text'>
                    <label>Create Date: </label>
                    <input type='radio' name='selectDateField' value={'date'} onClick={(e) => updateDateField('date')} defaultChecked /> <br />
                    <label>Sale Date: </label>
                    <input type='radio' name='selectDateField' value={'sale_date'} onClick={(e) => updateDateField('sale_date')} />
                </div>
            </div>
            <div className='ag-theme-quartz h-75 m-top-3 w-95 center'>
                <AgGridReact
                    gridRef={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    getGroupRowAgg={getGroupRowAgg}
                    gridOptions={gridOptions}
                    defaultColDef={defaultColDef}
                    modules={[ServerSideRowModelModule, RowGroupingModule]}
                />
            </div>
        </div>
    )
}
