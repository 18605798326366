import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise"
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import valueFormatter, { dateFormatterAddPSTSuffix, dateFormatterToUSDateTime } from '../valueFormatter'


// Currency Formater
const currencyFormatter = (currency, sign) => {
    if (currency === '' || currency === null || currency === undefined) {
        return `${sign}0.00`;
    }

    var sansDec = `${currency.toFixed(2)}`;
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (currency < 0) {
        return `(${sign}${formatted.replace("-", "")})`
    }
    return sign + `${formatted}`;
}

export function CallData(props) {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [filterModel, setFilterModel] = useState(null);  // Save current filters
    // const [loading, setLoading] = useState(true); // Loading state to track when data is being fetched


    // Grid Options
    const gridOptions = {
        rowGroupPanelShow: 'always',
        sideBar: true,
        grandTotalRow: true,
        // groupIncludeTotalFooter: true,
        getRowStyle: params => {
            if (params.node.group === true && params.node.level === -1) {
                return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
            }
            else if (params.node.group === true) {
                return { background: '#e8e8e8', fontWeight: 'bold' };
            }
        },
        autoGroupColumnDef: {
            cellRendererParams: {
              footerValueGetter: params => {
                const isRootLevel = params.node.level === -1;
                if (isRootLevel) {
                  return 'Grand Total';
                }
                return `Sub Total (${params.value})`;
              },
            },
            headerName: 'Source',
            pinned: 'left',
          },
    };
    

    // Group Row Agg func
    const getGroupRowAgg = useCallback((params) => {
        let result = {
            total_calls: 0,
            queued: 0,
            answered: 0,
            missed: 0,
            unique: 0,
        };

        let unique_numbers = {};

        params.nodes.forEach((node) => {
            const data = node.group ? node.aggData : node.data
            // Increment Total Calls
            result.total_calls += data.to_addr ? 1 : data.total_calls;
            // Increment Queued
            result.queued += data.queued;
            // Increment Answered
            result.answered += data.answered;
            // Increment Missed
            result.missed += data.missed;
            // Increment Unique ANIs
            if (data.formatted_from_addr) {
                if (!unique_numbers[data.formatted_from_addr]) {
                    result.unique += 1;
                    unique_numbers[data.formatted_from_addr] = true;
                }
            } else {
                result.unique += data.unique;
            }

        });

        return result;
    });


    // Get data from sources
    useEffect(() => {
        props.setLoading(true); // Set loading to true before fetching
        if (!props.userSources || !props.startDate || !props.endDate) {
            console.log("No sources or dates");
            return
        }


        if (gridRef.current) {
            // Save current filter state
            const currentFilterModel = gridRef.current.api.getFilterModel();
            setFilterModel(currentFilterModel);
        }

        // setRowData([]); // This might be an issue
        let sourceCodeArray = props.userSources.sources.split(",");
        let sourceCodeString = "";
        sourceCodeArray.forEach((element, index) => {
            sourceCodeString += "'" + element + "'"
            if (index < sourceCodeArray.length - 1) {
                sourceCodeString += ", "
            }
        });

        // Set Request Options
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + process.env.REACT_APP_DB_AUTH,
                'Accept': '*',
            },
            body: JSON.stringify({
                "startDate": props.startDate,
                "endDate": props.endDate,
                "userSources": sourceCodeString,
            })
        };

        // Request vendor data
        const reqUrl = process.env.REACT_APP_API_URL + "vendor/call_data/contact_start";

        fetch(reqUrl, requestOptions)
            .then(response => response.json())
            .then(resp => {
                setRowData(resp.data);
                props.setLoading(false); // Set loading to false after data is fetched
            })
    }, [props.userSources, props.startDate, props.endDate]);

    // After new data is set, reapply the filter model
    useEffect(() => {
        if (gridRef.current && filterModel) {
            gridRef.current.api.setFilterModel(filterModel);
        }
    }, [rowData]);


    // Default Column Def
    const defaultColDef = {
        filter: true,
        enableRowGroup: true,
        // cellStyle: {
        //     fontWeight: 'bold',
        // }
    };

    // Instantiate Col Defs
    let colDefs = [];
    if (props.userSources) {
        // Vendor Column Defnitions
        colDefs = [
            { field: "description", headerName: "Source", sort: "asc", rowGroup: true, hide:true, 
                valueGetter: params => {
                    return params.data ? `${params.data.description} - ${params.data.to_addr}` : '';
                } 
            },
            { field: "total_calls", headerName: "Total Calls", sort: "asc", cellStyle: { textAlign: 'right' },
                headerTooltip: "Total number of calls",
                tooltipValueGetter: (p) =>
                    "Datetime the call was received",
                valueGetter: params => {
                    if (params.node.group) {
                        return params.data.total_calls; // Return the aggregated count for group rows
                    }
                    
                    return params.data.formatted_contact_start_pst; // Return the start time for leaf nodes
                },
            },
            { field: "queued", headerName: "Queued", cellStyle: { textAlign: 'right' },
                headerTooltip: "Total number of calls that were queued",
                tooltipValueGetter: (p) =>
                    "Amount of time the call was in queue",
                valueGetter: params => {
                    if (params.node.group) {
                        return params.data.queued; // Return the aggregated count for group rows
                    }
                    return params.data.in_queue_seconds; // Return the in queue seconds for leaf nodes
                },
                valueFormatter: params => {
                    if (!params.node.group) {
                        return `${params.value} (s)` // Appends (s) to the leaf nodes to indicate seconds
                    }
                }
            },
            { field: "answered", headerName: "Answered", cellStyle: { textAlign: 'right' },
                headerTooltip: "Total number of calls that were answered",
                tooltipValueGetter: (p) =>
                    "Amount of time the call lasted after being answered",
                valueGetter: params => {
                    if (params.node.group) {
                        return params.data.answered; // Return the aggregated count for group rows
                    }
                    return params.data.agent_seconds; // Return the agent seconds for leaf nodes
                },
                valueFormatter: params => {
                    if (!params.node.group) {
                        return `${params.value} (s)` // Appends (s) to the leaf nodes to indicate seconds
                    }
                },
            },
            { field: "missed", headerName: "Missed",
                headerTooltip: "Total number of calls that were missed (missed calls are highlighted in red)",
                // tooltipValueGetter: (p) =>
                //     "",
                cellStyle: params => { 
                    if (params.data && params.data.abandoned) {
                        return { textAlign: 'right', color: 'red', fontWeight: 'bold', };
                    } else {
                        return { textAlign: 'right', };
                    }
                 },
                valueGetter: params => {
                    if (params.node.group) {
                        return params.data.missed; // Return the aggregated count for group rows
                    }
                    return (params.data.abandoned ? "Yes" : "No"); // Return where the call was abandoned for leaf nodes
                },
            },
            { field: "unique", headerName: "Unique",
                headerTooltip: "Total number of unique callers (duplicates are highlighted red)",
                tooltipValueGetter: (p) =>
                    "The caller's phone number",
                valueGetter: params => {
                    if (params.node.group) {
                        return params.data.unique; // Return the aggregated count for group rows
                    }
                    return params.data.formatted_from_addr; // Return the in queue seconds for leaf nodes
                },
                valueFormatter: params => {
                    if (!params.node.group) {
                        return params.value // Return the ANI for leaf nodes
                    }
                },
                cellStyle: params => {
                    // Only execute for leaf nodes
                    if (!params.node.group) {
                        // Get the parent group node
                        const groupNode = params.node.parent;
                        // Get all child nodes in this group
                        const siblings = groupNode.allLeafChildren;
                        // Holds the count of duplicates for this group
                        const groupDuplicates = {};

                        // Count formatted_from_addr occurrences within this group only
                        for (let node of siblings) {
                            const fromAddr = node.data.formatted_from_addr;
                            if (fromAddr) {
                                groupDuplicates[fromAddr] = (groupDuplicates[fromAddr] || 0) + 1;
                                // Early exit if we found a duplicate for current cell
                                if (fromAddr === params.data.formatted_from_addr && groupDuplicates[fromAddr] > 1) {
                                    return { 
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        color: '#FF4D4D'
                                    };
                                }
                            }
                        }
                    }
                    return { textAlign: 'right' };
                },
            },
        ];
    }

    // Autosize the grouped column when the grid is first rendered
    const onFirstDataRendered = (params) => {
        setTimeout(() => {
            params.columnApi.autoSizeAllColumns();
        },100);
    };

    // Autosizes all columns when row group is expanded
    const onRowGroupOpened = (params) => {
        setTimeout(() => {
            params.columnApi.autoSizeAllColumns();
        },100);
    };

    return (
        <div>
            <div className='ag-theme-quartz h-75 m-top-3 w-95 center' style={{ position: 'relative' }}>
                <AgGridReact
                    onFirstDataRendered={onFirstDataRendered}
                    onRowGroupOpened={onRowGroupOpened}
                    enableCharts={true}
                    enableRangeSelection={true}
                    gridRef={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    getGroupRowAgg={getGroupRowAgg}
                    gridOptions={gridOptions}
                    defaultColDef={defaultColDef}
                    modules={[ServerSideRowModelModule, RowGroupingModule]}
                    defaultSideBar={false}
                    sideBar={{
                        toolPanels: [
                            {
                                id: 'columns',
                                labelDefault: 'Columns',
                                labelKey: 'columns',
                                iconKey: 'columns',
                                toolPanel: 'agColumnsToolPanel',
                            },
                            {
                                id: 'filters',
                                labelDefault: 'Filters',
                                labelKey: 'filters',
                                iconKey: 'filter',
                                toolPanel: 'agFiltersToolPanel',
                            }
                        ]
                    }}
                />
                {props.loading && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                    </div>
                )}
            </div>
        </div>
    )
};

export default CallData;
