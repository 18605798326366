import './App.css';
import Login from './components/login/Login';
import Signup from './components/signup/Signup';
import Dashboard from './components/dashboard/Dashboard';
import { useEffect, useState } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const vendorUsersUrl = process.env.REACT_APP_VENDOR_SHEET_URL;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
  const [vendorMap, setVendorMap] = useState([]);

  // Create Vendor User Map
  const extractVendors = (data) => {
    let vendorMap = {};
    const vals = data.values;
    for (let i = 0; i < vals.length; i++) {
      vendorMap[vals[i][0]] = { "sources": vals[i][1], "admin": vals[i][2], "dataTypes": vals[i][3] };
    }
    return vendorMap;
  }

  // Manager Email Fetch
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(vendorUsersUrl, requestOptions)
      .then(result => result.json())
      .then(data => {
        let vendorMap = extractVendors(data);
        setVendorMap(vendorMap);
      })
      .catch(err => console.log("ERROR: " + err))
  }, []);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login app={app} />} />
        <Route path="/signup" element={<Signup app={app} />} />
        <Route path="/dashboard" element={<Dashboard app={app} vendorMap={vendorMap} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
